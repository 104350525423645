import { useTitle } from "../utils/useTitle";
import { useAuth0 } from "@auth0/auth0-react";
import { Loader } from "./Loader";

const Home = () => {
  useTitle("RevealTwin");

  const { loginWithRedirect, isLoading } = useAuth0();

  return (
    <>
      {isLoading ? (
        <Loader />
      ) : (
        <div className="relative flex items-center justify-center overflow-hidden flex-grow">
          <div className="relative z-30 p-5 text-center">
            <h1 className="text-5xl font-black tracking-tight mb-5">
              Welcome to the Reveal Platform
            </h1>
            <div className="pt-2">
              <button
                className="bg-revealRed font-bold text-white rounded-full py-4 px-8 shadow-lg uppercase tracking-wider hover:opacity-80 block xs:inline-block my-2 mx-auto xs:m-2"
                onClick={() => loginWithRedirect()}
              >
                Sign in
              </button>
              <a
                href="https://www.reveal.nz/contact"
                target="_blank"
                rel="noreferrer"
                className="bg-gray-400 font-bold text-white rounded-full py-4 px-8 shadow-lg uppercase tracking-wider hover:opacity-80 block xs:inline-block my-2 mx-auto xs:m-2"
              >
                Find out more
              </a>
            </div>
          </div>
          <video
            autoPlay={true}
            loop
            muted
            className="bg-white opacity-50 absolute w-auto min-w-full min-h-full max-w-none"
          >
            <source src="./Taranaki-St-Flythrough.mp4" type="video/mp4" />
            Your browser does not support the video tag.
          </video>
        </div>
      )}
    </>
  );
};

export default Home;
