import { useMemo } from "react";
import { useLocalStore } from "./useLocalStore";

export const useUserPreferredPage = (
  defaultPage: string,
  preferredPageWhiteList: string[]
) => {
  const { getItem, setItem } = useLocalStore();
  const userPreferredPage = useMemo(() => {
    const preferredPage = getItem("userPreferredPage");

    return preferredPage && preferredPageWhiteList.includes(preferredPage)
      ? preferredPage
      : defaultPage;
  }, [defaultPage, getItem, preferredPageWhiteList]);
  const setUserPreferredPage = (preferredPage: string) =>
    setItem("userPreferredPage", preferredPage);

  return {
    userPreferredPage,
    setUserPreferredPage
  };
};
