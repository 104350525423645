import React, { ReactNode } from "react";
import { createRoot } from "react-dom/client";
import "./index.css";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import { AppState, Auth0Provider } from "@auth0/auth0-react";
import * as serviceWorker from "./serviceWorker";
import * as Sentry from "@sentry/react";
import { BrowserTracing } from "@sentry/tracing";
import { CaptureConsole as CaptureConsoleIntegration } from "@sentry/integrations";
import { BrowserRouter as Router, useNavigate } from "react-router-dom";
import { config } from "./config";
import TagManager from "react-gtm-module";
import { pdfjs } from "react-pdf";
import "react-pdf/dist/Page/TextLayer.css";
import "react-pdf/dist/Page/AnnotationLayer.css";
import "pdfjs-dist/build/pdf.worker.entry";

const Auth0ProviderWithNavigate: React.FC<{ children: ReactNode }> = ({
  children
}) => {
  const navigate = useNavigate();
  const onRedirectCallback = (appState?: AppState) => {
    const returnTo = appState?.returnTo;
    if (returnTo) {
      navigate(returnTo);
    }
  };

  return (
    <Auth0Provider
      domain={config.auth0.domain}
      clientId={config.auth0.clientId}
      connection={config.auth0.connection}
      audience={config.auth0.audience}
      redirectUri={`${window.location.origin}`}
      onRedirectCallback={onRedirectCallback}
    >
      {children}
    </Auth0Provider>
  );
};

/**
 * @TODO this should likely be a boolean value we set somewhere, putting a true
 * statement in the env file makes it a string so you still have to validate x === "true"
 */
if (config.environment !== "local") {
  Sentry.init({
    dsn: "https://c9d9c1f57217470a8c03bbaa2617c3d1@o1253121.ingest.sentry.io/6510245",
    environment: config.environment,
    integrations: [
      new BrowserTracing(),
      new CaptureConsoleIntegration({
        // array of console logging methods that should be captured
        // defaults to ['error']
        levels: ["error", "warn"]
      })
    ],
    tracesSampleRate: 1
  });

  TagManager.initialize({
    gtmId: config.gtm.id,
    auth: config.gtm.auth,
    preview: config.gtm.preview
  });
}

pdfjs.GlobalWorkerOptions.workerSrc = new URL(
  "pdfjs-dist/build/pdf.worker.min.js",
  import.meta.url
).toString();

const container = document.getElementById("root");
const root = createRoot(container!);

root.render(
  <React.StrictMode>
    <Router>
      <Auth0ProviderWithNavigate>
        <App />
      </Auth0ProviderWithNavigate>
    </Router>
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: http://bit.ly/CRA-PWA
if (config.nodeEnv === "production") {
  serviceWorker.register();
} else {
  serviceWorker.unregister();
}
