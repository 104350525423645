import { Spinner } from "./common/Spinner/Spinner";

export const Loader = () => {
  return (
    <div className="flex flex-col h-screen">
      <div className="w-full h-full fixed block top-0 left-0 z-50">
        <div className="top-1/2 my-0 mx-auto block relative w-0 h-0">
          <Spinner size={16} />
        </div>
      </div>
    </div>
  );
};
