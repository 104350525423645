import { useEffect } from "react";

type NewRelicApi = {
  addPageAction: (
    actionName: string,
    attributes: Record<string, string | number>
  ) => void;
  setCustomAttribute: (
    key: string,
    value: string | number | boolean | null,
    persist?: boolean
  ) => void;
  log: (
    message: string,
    options?: {
      attributes?: Record<string, string | number>;
      level?: "debug|error|info|trace|warn";
    }
  ) => void;
};
declare global {
  interface Window {
    newrelic: NewRelicApi;
  }
}
export const useNewRelic = (): NewRelicApi => {
  return newRelic;
};

const newRelic: NewRelicApi = {
  addPageAction: function (
    actionName: string,
    attributes: Record<string, string | number>
  ): void {
    window.newrelic?.addPageAction(actionName, attributes);
  },
  setCustomAttribute: function (
    key: string,
    value: string | number | boolean | null,
    persist?: boolean
  ): void {
    window.newrelic?.setCustomAttribute(key, value, persist);
  },
  log: function (
    message: string,
    options?: {
      attributes?: Record<string, string | number>;
      level?: "debug|error|info|trace|warn";
    }
  ): void {
    window.newrelic?.log(message, options);
  }
};

export const useNewRelicCustomAttribute = (
  key: string,
  value: string | number | boolean | undefined
) => {
  const { setCustomAttribute } = useNewRelic();
  useEffect(() => {
    setCustomAttribute(key, value ?? null);
  }, [key, setCustomAttribute, value]);
};
