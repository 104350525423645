import { withAuthenticationRequired } from "@auth0/auth0-react";
import { FC, ReactNode, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { Loader } from "../../Loader";
import { CanProps, useAbility } from "../../casl/hooks/useAbility";

type AuthCheckProps = {
  redirectUnauthorisedUserTo: string;
  onLoading?: ReactNode;
  children: ReactNode;
} & CanProps;

const AuthCheck: FC<AuthCheckProps> = ({
  children,
  redirectUnauthorisedUserTo,
  onLoading = <Loader />,
  ...canProps
}) => {
  const { can, isReady } = useAbility();
  const navigate = useNavigate();

  useEffect(() => {
    if (!isReady) {
      return;
    }
    const isAuthorised = can(canProps);
    if (!isAuthorised) {
      navigate(redirectUnauthorisedUserTo, { replace: true });
    }
  }, [can, canProps, isReady, navigate, redirectUnauthorisedUserTo]);

  return isReady ? <>{children}</> : <>{onLoading}</>;
};

export default withAuthenticationRequired(AuthCheck, {
  onRedirecting: () => <Loader />
});
