import { FC } from "react";

const OpenLinkIcon: FC<{ className?: string }> = ({
  className = "h-5 w-5 stroke-cyan-500"
}) => (
  <svg
    className={className}
    viewBox="0 0 19 19"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M10.6875 4.75H4.15625C3.68383 4.75 3.23077 4.93767 2.89672 5.27172C2.56267 5.60577 2.375 6.05883 2.375 6.53125V14.8438C2.375 15.3162 2.56267 15.7692 2.89672 16.1033C3.23077 16.4373 3.68383 16.625 4.15625 16.625H12.4688C12.9412 16.625 13.3942 16.4373 13.7283 16.1033C14.0623 15.7692 14.25 15.3162 14.25 14.8438V8.3125M5.9375 13.0625L16.625 2.375M16.625 2.375H12.4688M16.625 2.375V6.53125"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);
export default OpenLinkIcon;
