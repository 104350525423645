import React, { ComponentType, ReactNode } from "react";
import { createPortal } from "react-dom";

const POSITION_CLASS_MAP = {
  center: "justify-center items-center min-h-full",
  centerTop: "justify-center items-start",
  centerBottom: "justify-center items-end min-h-full",
  left: "justify-start items-center min-h-full",
  leftBottom: "justify-start items-end min-h-full",
  leftTop: "justify-start items-start",
  right: "justify-end items-center min-h-full",
  rightBottom: "justify-end items-end min-h-full",
  rightTop: "justify-end items-start mt-16 mr-3"
};

export type ModalProps = {
  className?: string;
  wrapperClassName?: string;
  showBackdrop?: boolean;
  modalDivId?: string;
  children?: ReactNode;
  position?: keyof typeof POSITION_CLASS_MAP;
  noHeightRestriction?: boolean;
  zHeight?: string;
};

export const Modal: ComponentType<ModalProps> = ({
  modalDivId = "modal-root",
  children,
  className = "",
  wrapperClassName = "w-full sm:max-w-lg",
  showBackdrop,
  position = "center",
  noHeightRestriction,
  zHeight = "z-30"
}) => {
  const positionClass = POSITION_CLASS_MAP[position];

  return createPortal(
    <div
      className={`relative pointer-events-none ${zHeight}`}
      aria-labelledby="modal-title"
      role="dialog"
      aria-modal="true"
    >
      {showBackdrop && (
        <div className="fixed inset-0 bg-gray-700 bg-opacity-50 transition-opacity pointer-events-auto"></div>
      )}
      <div className="fixed inset-0 overflow-y-auto bg-opacity-75">
        <div
          className={`flex ${
            noHeightRestriction ? "" : "min-h-fit-content"
          } text-center sm:p-0 ${positionClass}`}
        >
          <div
            className={`relative transform m-4 overflow-hidden rounded-lg bg-white text-left  pointer-events-auto ${wrapperClassName}`}
          >
            <div className={className}>{children}</div>
          </div>
        </div>
      </div>
    </div>,
    document.getElementById(modalDivId)!
  );
};
