import { useAuth0 } from "@auth0/auth0-react";
import {
  ChatBubbleLeftRightIcon,
  EnvelopeIcon,
  XMarkIcon
} from "@heroicons/react/24/solid";
import { FC } from "react";
import { useHubspotChat } from "../../../../api/hooks/useHubspotChat";
import {
  ContactMetadata,
  useContactContext
} from "../../../../contexts/ContactContext";
import { Button } from "../../../common/ButtonV2/Button";
import { Modal } from "../../../common/Modal/Modal";
import { RevealIcon } from "../../../common/SvgLibrary/RevealIcon";

type ContactUsProps = { contactMetadata?: ContactMetadata };

export const ContactUs: FC<ContactUsProps> = ({ contactMetadata }) => {
  const auth0 = useAuth0();
  const { user } = auth0;
  const { closeContactModal } = useContactContext();

  const { chatWidgetLoaded, openChatWidget } = useHubspotChat();

  const startHubspotChat = () => {
    openChatWidget();
    closeContactModal();
  };

  return (
    <Modal
      position="centerTop"
      showBackdrop
      wrapperClassName="w-full sm:max-w-sm sm:m-16"
      className="p-0"
    >
      <div className="bg-gray-900 px-6 py-4 flex items-center justify-between">
        <div className="flex items-center gap-4">
          <RevealIcon />
          <h2 className="text-gray-100 reveal-font-title">Contact Reveal</h2>
        </div>
        <Button
          variant="iconOnly"
          onClick={() => closeContactModal()}
          color="secondary"
          className="-my-1.5 -mx-4"
          size="regular"
          mode="dark"
          Icon={XMarkIcon}
          iconVariant="solid"
        />
      </div>
      <div className="flex flex-col px-2 divide-y">
        {chatWidgetLoaded && (
          <div className="flex gap-4 items-top py-4 px-6 col-span-2 ">
            <ChatBubbleLeftRightIcon className="h-6 w-6 fill-gray-500 shrink-0 mt-1" />
            <div>
              <h3 className="reveal-font-title">Chat with us</h3>
              <p className="text-gray-600 mb-2 reveal-font-body">
                We reply during business hours Monday - Friday, 9am - 5pm NZST
              </p>

              <Button
                variant="filled"
                color="primary"
                size="regular"
                mode="light"
                onClick={() => startHubspotChat()}
                className="reveal-font-body"
              >
                Start chat
              </Button>
            </div>
          </div>
        )}
        <div className="flex gap-4 items-top py-4 px-6 ">
          <EnvelopeIcon className="h-6 w-6 fill-gray-500 shrink-0 mt-1" />
          <div>
            <h3 className="reveal-font-title ">Send us an email</h3>
            <p className="text-cyan-600 underline mb-2 reveal-font-body">
              {" "}
              <a
                href={`mailto:support@reveal.nz?body=%0D%0A%0D%0A%0D%0A%0D%0A${
                  contactMetadata?.enquiringAbout
                    ? `Enquiring about:%20${contactMetadata?.enquiringAbout}%0D%0A%20`
                    : ""
                }Username:%20${
                  user?.email
                }%0D%0ATime:%20${new Date().toLocaleString()}%0D%0AUrl:%20${escape(
                  window.location.href
                )}`}
              >
                support@reveal.nz
              </a>
            </p>
          </div>
        </div>
      </div>
    </Modal>
  );
};
