import { FC } from "react";

type ButtonProps = {
  className?: string;
  mode?: "light" | "dark";
  variant?: "primary" | "secondary";
  size?: "small" | "regular";
};

const STYLES = {
  primary: { light: "text-cyan-600", dark: "text-cyan-600" },
  secondary: { light: "text-gray-600", dark: "text-white" }
};

const SIZES = { small: "h-4 w-5", regular: "h-5 w-5" };

export const LoadingSpinner: FC<ButtonProps> = ({
  size = "regular",
  mode = "dark",
  variant = "secondary",
  className
}) => (
  <svg
    className={`animate-spin stroke-none fill-none ${className} ${SIZES[size]} ${STYLES[variant][mode]}`}
    xmlns="http://www.w3.org/2000/svg"
    fill="none"
    viewBox="0 0 24 24"
  >
    <circle
      className="opacity-25"
      cx="12"
      cy="12"
      r="10"
      stroke="currentColor"
      strokeWidth="4"
    ></circle>
    <path
      className="opacity-75"
      fill="currentColor"
      stroke="none"
      d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
    ></path>
  </svg>
);
