import { revealFetcher, useRevealApi } from "./revealFetch";
import {
  OrganisationForm,
  OrganisationEditForm
} from "../components/admin/types";
import { Auth0ContextInterface, User } from "@auth0/auth0-react";
import { Profile, ProfileDto } from "./types/profile";
import { useMemo } from "react";
import { AbilityBuilder } from "@casl/ability";
import { UndermapsAbility } from "../components/casl/types";

export const addOrganisation = async (
  organisation: OrganisationForm,
  auth0: Auth0ContextInterface<User>
): Promise<Response> => {
  return await revealFetcher(auth0).fetch("/organisations", {
    method: "POST",
    body: JSON.stringify(organisation)
  });
};

export const editOrganisation = async (
  organisation: OrganisationEditForm,
  auth0: Auth0ContextInterface
): Promise<Response> => {
  return await revealFetcher(auth0).fetch(
    `/admin/organisations/${organisation.orgId}`,
    {
      method: "PUT",
      body: JSON.stringify(organisation)
    }
  );
};

export const useProfile = () => {
  const { data, error, refresh, loading } =
    useRevealApi<ProfileDto>("/admin/profile");
  const profile = useMemo(() => {
    if (!data) {
      return undefined;
    }
    return new Profile(data);
  }, [data]);
  return {
    data: profile,
    error,
    refresh,
    loading
  };
};

export const useAuthorisationRules = () => {
  return useRevealApi<AbilityBuilder<UndermapsAbility>["rules"]>(
    "/admin/authorisationRules"
  );
};
