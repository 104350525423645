import { createContext, useContext } from "react";
import { modes } from "../utils/modes";
import { Profile } from "../api/types/profile";
import { RevealRefreshableState, RevealState } from "../api/revealFetch";
import { AbilityBuilder } from "@casl/ability";
import { UndermapsAbility } from "../components/casl/types";

export type AppContextType = {
  mode: string | undefined;
  setMode: (t: string) => void;
  /** @deprecated instead profileAsync */
  profile: Profile | undefined;
  profileAsync: RevealRefreshableState<Profile>;
  authorisationRules: RevealState<AbilityBuilder<UndermapsAbility>["rules"]>;
};

export const AppContext = createContext<AppContextType>({
  profile: undefined,
  mode: modes.EXPLORE,
  setMode: () => {},
  profileAsync: { loading: false, refresh: () => {} },
  authorisationRules: { loading: false }
});
export const useAppContext = () => useContext(AppContext);
