const basePaths = {
  ADMIN: "/admin",
  DATA_MODEL: "/dataModel"
};

export const appRoutes = {
  ROOT: "/",
  MAP: "/map",
  PROJECTS: "/projects",
  MAP_2D: "/map/2d",
  COLLECT: "/collect",
  COLLECT_DEVICE_SETTINGS: "/collect/device-settings",
  COLLECT_3D: "/3D",
  EDIT: "/editor",
  AERIAL: "/aerial",
  TRANSFORMER: "/transformer",
  ADMIN: basePaths.ADMIN,
  DATA_MODEL: basePaths.DATA_MODEL,
  UNDERDOTS: `/underdots`,
  UNDERMAPS: `/undermaps`
};

export const externalRoutes = {
  USER_DOCS: "https://revealtech.atlassian.net/wiki/spaces/UD1/overview"
};
