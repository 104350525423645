import { createContext, FC, ReactNode, useContext, useState } from "react";
import { ContactUs } from "../components/underdots/common/ContactUs/ContactUs";

const ContactContext = createContext<ContactContextType>(null!);

export type ContactMetadata = { enquiringAbout: string };

export type ContactContextType = {
  closeContactModal: () => void;
  openContactModal: (contactMetadata?: ContactMetadata) => void;
};

export const useContactContext = (emailMetadata?: string) =>
  useContext(ContactContext);

export const ContactContextProvider: FC<{ children: ReactNode }> = ({
  children
}) => {
  const [contactModalOpen, setContactModalOpen] = useState<boolean>(false);
  const [contactMetadata, setContactMetadata] = useState<ContactMetadata>();

  const openContactModal = (metadata?: ContactMetadata) => {
    setContactModalOpen(true);
    setContactMetadata(metadata);
  };

  const closeContactModal = () => setContactModalOpen(false);

  return (
    <ContactContext.Provider value={{ openContactModal, closeContactModal }}>
      {contactModalOpen && <ContactUs contactMetadata={contactMetadata} />}
      {children}
    </ContactContext.Provider>
  );
};
