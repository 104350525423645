import { FC } from "react";

export interface SpinnerProps {
  size: number;
}

export const Spinner: FC<SpinnerProps> = ({ size }) => {
  return (
    <div
      className={`border-revealRed w-${size} h-${size} border-4 border-solid rounded-full animate-spin`}
      style={{
        borderTopColor: "transparent"
      }}
    />
  );
};
