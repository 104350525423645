import { useAuth0, withAuthenticationRequired } from "@auth0/auth0-react";
import { FC, useCallback, useEffect, useState } from "react";
import { Loader } from "../Loader";
import { revealFetcher } from "../../api/revealFetch";

const DownloadableImage: FC<{
  path: string;
  filename: string;
  caption: string;
}> = ({ path, filename, caption }) => {
  const [imgObject, setImgObject] = useState<string>();
  const auth0 = useAuth0();
  const fetchImage = useCallback(async () => {
    const res = await revealFetcher(auth0).fetch(path);
    const imageBlob = await res.blob();
    return URL.createObjectURL(imageBlob);
  }, [auth0, path]);
  const fetchCallback = useCallback(async () => {
    setImgObject(await fetchImage());
  }, [fetchImage]);
  const download = async () => {
    const link = document.createElement("a");
    link.href = await fetchImage();
    link.download = filename;
    link.click();
  };
  useEffect(() => {
    fetchCallback();
  }, [fetchCallback]);
  return (
    <div className="place-items-center items-stretch border-x-4 hover:border-indigo-500 hover:cursor-pointer text-center align-middle">
      <button onClick={download} className="place-items-center">
        {imgObject && <img className="h-48" src={imgObject} alt={caption} />}
        {!imgObject && (
          <div className="h-48 grid grid-cols-1 place-items-center">
            <div
              className="border-revealRed w-16 h-16 border-4 border-solid rounded-full animate-spin"
              style={{
                borderTopColor: "transparent"
              }}
            />
          </div>
        )}
      </button>
      <div className="w-full text-center">
        <p>{caption}</p>
      </div>
    </div>
  );
};

const DataModel: FC = () => {
  return (
    <div className="bg-gray-100 h-screen ">
      <div className=" sm:container mx-auto xs:px-6 lg:px-8">
        <div className="my-5 py-2 w-full grid grid-cols-2  items-center ">
          <div className="flex items-center py-2 overflow-y-auto whitespace-nowrap">
            <p className="text-gray-800 font-medium text-lg">Data Model</p>
          </div>
          <div className="sm:justify-self-end space-x-2" />
        </div>
        <div className="grid grid-cols-3 gap-4">
          <DownloadableImage
            path="dataModel/classes"
            filename="classes.png"
            caption={"Classes"}
          />
          <DownloadableImage
            path="dataModel/enums"
            filename="enums.png"
            caption={"Enumerations"}
          />
          <DownloadableImage
            path="dataModel/attrs"
            filename="attrs.png"
            caption={"Well-Known Values"}
          />
        </div>
      </div>
    </div>
  );
};

export default withAuthenticationRequired(DataModel, {
  onRedirecting: () => <Loader />
});
