import { FC } from "react";

export const ExitIcon: FC<{ className?: string }> = ({
  className = "h-6 w-6"
}) => (
  <svg
    className={className}
    xmlns="http://www.w3.org/2000/svg"
    fill="fill-white"
    viewBox="0 0 24 24"
    stroke="currentColor"
  >
    <path
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth="2"
      d="M6 18L18 6M6 6l12 12"
    />
  </svg>
);
