const Error = () => {
  return (
    <div
      className="
    flex
    items-center
    justify-center
    w-screen
    h-screen
   
  "
    >
      <div className="flex flex-col items-center">
        <h1
          className="font-bold text-blue-600 text-5xl mb-5"
          data-testid="error-heading"
        >
          Something has gone wrong
        </h1>

        <p className="mb-8 text-center text-gray-500 md:text-lg">
          Reload the page to try again
        </p>
      </div>
    </div>
  );
};

export default Error;
