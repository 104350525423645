import { UserOrganisation } from "./organisation";
import { User } from "./user";

export type ProfileDto = {
  user: User;
  organisations: (UserOrganisation & {
    children: UserOrganisation[];
  })[];
};

export class Profile {
  public readonly user: User;
  public readonly organisations: (UserOrganisation & {
    children: UserOrganisation[];
  })[];
  constructor(dto: ProfileDto) {
    this.user = dto.user;
    this.organisations = dto.organisations;
  }
}
