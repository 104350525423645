import { Project } from "../../../api/types/project";
import { useAppContext } from "../../../contexts/AppContext";
import { AbilityBuilder, createMongoAbility, subject } from "@casl/ability";
import { useCallback, useMemo } from "react";
import {
  UndermapsAbility,
  UndermapsAction,
  UndermapsApplicationAction,
  UndermapsApplicationSubject,
  UndermapsOrganisationAction,
  UndermapsProjectAction,
  UndermapsSiteAction
} from "../types";
import { Site } from "../../undermaps/types";
import { Organisation } from "../../../api/types/organisation";

type UndermapsCanProps = {
  type: "Undermaps";
  action: UndermapsAction;
};

type ApplicationCanProps = {
  type: "Application";
  action: UndermapsApplicationAction;
  subject: UndermapsApplicationSubject;
};

type ProjectCanProps = {
  type: "Project";
  action: UndermapsProjectAction;
  project: Partial<Project>;
};

type SiteCanProps = {
  type: "Site";
  action: UndermapsSiteAction;
  site: Partial<Site>;
};

type OrganisationCanProps = {
  type: "Organisation";
  action: UndermapsOrganisationAction;
  organisation: Partial<Organisation>;
};

export type CanProps =
  | UndermapsCanProps
  | ApplicationCanProps
  | SiteCanProps
  | OrganisationCanProps
  | ProjectCanProps;

/**
 * Provides a means of querying authorisation for the user based on their profile.
 */
export type Ability = {
  projectAbility: {
    actionsFor: () => string[];
    can: (action: UndermapsProjectAction, project: Partial<Project>) => boolean;
  };
  siteAbility: {
    actionsFor: () => string[];
    can: (action: UndermapsSiteAction, site: Partial<Site>) => boolean;
  };
  undermapsAbility: {
    actionsFor: () => string[];
    can: (action: UndermapsAction) => boolean;
  };
  applicationAbility: {
    can: (
      action: UndermapsApplicationAction,
      subject: UndermapsApplicationSubject
    ) => boolean;
  };
  organisationAbility: {
    actionsFor: () => string[];
    can: (
      action: UndermapsOrganisationAction,
      organisation: Partial<Organisation>
    ) => boolean;
  };
  can: (props: CanProps) => boolean;
  isReady: boolean;
};

/**
 * Provides a means of interrogating user permissions based on the current app context.
 */
export const useAbility = (): Ability => {
  const {
    authorisationRules: { data: rules }
  } = useAppContext();
  const ability: UndermapsAbility = useMemo(() => {
    const abilityBuilder = new AbilityBuilder<UndermapsAbility>(
      createMongoAbility
    );
    if (rules) {
      abilityBuilder.rules = rules;
    }
    return abilityBuilder.build();
  }, [rules]);
  const projectAbility = useMemo(() => {
    return {
      actionsFor: () => ability.actionsFor("Project"),
      can: (action: UndermapsProjectAction, project: Partial<Project>) =>
        ability.can(
          action,
          subject(
            "Project",
            { ...project } // must make a copy of the object because React prevents object extensions to component props
          )
        )
    };
  }, [ability]);
  const siteAbility = useMemo(() => {
    return {
      actionsFor: () => ability.actionsFor("Site"),
      can: (action: UndermapsSiteAction, site: Partial<Site>) =>
        ability.can(
          action,
          subject(
            "Site",
            { ...site } // must make a copy of the object because React prevents object extensions to component props
          )
        )
    };
  }, [ability]);
  const organisationAbility = useMemo(() => {
    return {
      actionsFor: () => ability.actionsFor("Organisation"),
      can: (
        action: UndermapsOrganisationAction,
        organisation: Partial<Organisation>
      ) =>
        ability.can(
          action,
          subject(
            "Organisation",
            { ...organisation } // must make a copy of the object because React prevents object extensions to component props
          )
        )
    };
  }, [ability]);
  const undermapsAbility = useMemo(
    () => ({
      actionsFor: () => ability.actionsFor("Undermaps"),
      can: (action: UndermapsAction) => ability.can(action, "Undermaps")
    }),
    [ability]
  );
  const applicationAbility = useMemo(
    () => ({
      can: (
        action: UndermapsApplicationAction,
        subject: UndermapsApplicationSubject
      ) => ability.can(action, subject)
    }),
    [ability]
  );
  const can = useCallback(
    (props: CanProps): boolean => {
      const { type, action } = props;
      let can: boolean = false;
      if (type === "Project") {
        can = projectAbility.can(action, props.project);
      } else if (type === "Site") {
        can = siteAbility.can(action, props.site);
      } else if (type === "Organisation") {
        can = organisationAbility.can(action, props.organisation);
      } else if (type === "Undermaps") {
        can = undermapsAbility.can(action);
      } else if (type === "Application") {
        can = applicationAbility.can(action, props.subject);
      } else {
        throw new Error("Unexpected type in CaslVisible component: " + type);
      }
      return can;
    },
    [
      applicationAbility,
      organisationAbility,
      projectAbility,
      siteAbility,
      undermapsAbility
    ]
  );
  return {
    projectAbility,
    undermapsAbility,
    siteAbility,
    organisationAbility,
    applicationAbility,
    can,
    isReady: !!rules
  };
};
