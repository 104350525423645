import { useCallback, useEffect, useState } from "react";
import { Auth0ContextInterface, useAuth0 } from "@auth0/auth0-react";
import { config } from "../config";

const AUTH0_SCOPE = "openid profile email";

export type RevealRequestInit = RequestInit & {
  skipContentType?: boolean;
  allowUnauthenticated?: boolean;
};

export class FetchError extends Error {
  constructor(
    message?: string,
    public readonly json?: any,
    public readonly status?: number
  ) {
    super(json?.message || message);
  }
}

/**
 * A wrapper for fetch() that injects the base API URL and Auth0 token
 *
 * @param auth0 the Auth0ContextInterface (retrieved with useAuth0())
 */
export const revealFetcher = (auth0: Auth0ContextInterface) => {
  return {
    /**
     *
     * @param path the relative path of the API endpoint, e.g., /auth/arcGisToken
     * @param options
     * @return the Response object
     */
    fetch: async (path: string, options?: RevealRequestInit) => {
      const { headers, ...fetchOptions } = options ?? {};

      const authorizationHeader =
        options?.allowUnauthenticated && !auth0.isAuthenticated
          ? {}
          : {
              Authorization: `Bearer ${await auth0.getAccessTokenSilently({
                audience: config.auth0.audience,
                scope: AUTH0_SCOPE
              })}`
            };

      const url =
        config.apiBaseUrl + (path.startsWith("/") ? path : "/" + path);
      const reqHeaders: Record<string, any> = {
        "Access-Control-Allow-Origin": "*",
        // Add the Authorization header to the existing headers
        ...authorizationHeader,
        ...headers
      };
      if (!options?.skipContentType) {
        reqHeaders["Content-type"] = "application/json";
      }
      return await fetch(url, {
        method: "GET",
        mode: "cors",
        referrerPolicy: "unsafe-url",
        ...fetchOptions,
        headers: reqHeaders
      });
    },
    /**
     *
     * @param path the relative path of the API endpoint, e.g., /auth/arcGisToken
     * @param options
     * @return a Promise for a value of type V
     * @throws FetchError
     */
    fetchValue: async <V extends unknown>(
      path: string,
      options?: RevealRequestInit
    ): Promise<V> => {
      const res = await revealFetcher(auth0).fetch(path, options);
      if (!res.ok) {
        let body: any = undefined;
        try {
          body = await res.json();
        } catch (x) {
          // ignore
        }
        throw new FetchError(res.statusText, body, res.status);
      }
      return (await res.json()) as V;
    }
  };
};

export interface RevealState<V extends unknown> {
  error?: any;
  loading: boolean;
  data?: V;
}

export interface RevealRefreshableState<V extends unknown>
  extends RevealState<V> {
  refresh: () => void;
}

/**
 * A useful way to fetch API state for a component
 *
 * @param path the path to the REST endpoint, use falsy value to skip the fetch
 * @param options fetch options
 */
export const useRevealApi = <V extends unknown>(
  path: string | undefined,
  options?: RevealRequestInit
) => {
  const [state, setState] = useState<RevealState<V>>({
    error: undefined,
    loading: true,
    data: undefined
  });
  const auth0 = useAuth0();
  const [refreshIndex, setRefreshIndex] = useState(0);

  useEffect(() => {
    const abortController = new AbortController();
    (async () => {
      if (!path) {
        return;
      }
      setState((previousValue) => ({ ...previousValue, loading: true }));

      try {
        const data = await revealFetcher(auth0).fetchValue<V>(path, {
          ...options,
          signal: abortController.signal
        });
        setState({
          data: data,
          error: undefined,
          loading: false
        });
      } catch (error) {
        setState({
          data: undefined,
          error,
          loading: false
        });
      }
    })();
    return () => {
      abortController.abort();
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [refreshIndex, path, options, auth0.getAccessTokenSilently]);

  const refresh = useCallback(() => {
    setRefreshIndex((prev) => prev + 1);
  }, [setRefreshIndex]);

  return {
    ...state,
    refresh
  };
};
