import { useAuth0 } from "@auth0/auth0-react";
import React, { FC, ReactNode } from "react";
import { Navigate } from "react-router-dom";
import { Loader } from "../../Loader";

type LoggedInRedirectProps = {
  redirectLoggedInUserTo: string;
  onLoading?: ReactNode;
  children: ReactNode;
};

export const LoggedInRedirect: FC<LoggedInRedirectProps> = ({
  children,
  redirectLoggedInUserTo,
  onLoading = <Loader />
}) => {
  const { isLoading, isAuthenticated } = useAuth0();

  if (isLoading) {
    return <>{onLoading}</>;
  }

  if (isAuthenticated) {
    return <Navigate to={redirectLoggedInUserTo} replace={true} />;
  }

  return <>{children}</>;
};
