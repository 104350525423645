import { useEffect, useState } from "react";

export const useExternalScripts = (url?: string) => {
  const [state, setState] = useState<"loading" | "idle" | "ready" | "error">(
    url ? "loading" : "idle"
  );

  useEffect(() => {
    if (!url) {
      setState("idle");
      return;
    }

    let script = document.querySelector(`script[src="${url}"]`);

    if (!script) {
      script = document.createElement("script");
      script.setAttribute("src", url);
      script.setAttribute("async", "true");
      script.addEventListener("load", () => setState("ready"));
      script.addEventListener("error", () => setState("error"));
      document.body.appendChild(script);
    }

    script.addEventListener("load", () => setState("ready"));
    script.addEventListener("error", () => setState("error"));

    return () => {
      script?.removeEventListener("load", () => setState("idle"));
      script?.removeEventListener("error", () => setState("idle"));
    };
  }, [url]);

  useEffect(() => {
    if (state === "error") {
      console.error(`Failed to load hubspot script`);
    }
  }, [state]);

  return state;
};
