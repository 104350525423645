import { useCallback, useEffect, useState } from "react";

export const useHubspotChat = () => {
  const [chatWidgetLoaded, setChatWidgetLoaded] = useState(false);
  const win = window as any;

  const status =
    win.HubSpotConversations && win.HubSpotConversations.widget.status();

  useEffect(() => {
    if (status && status.loaded) {
      setChatWidgetLoaded(true);
    }
  }, [status]);

  const openChatWidget = useCallback(() => {
    if (chatWidgetLoaded) {
      win.HubSpotConversations.widget.open();
    }
  }, [chatWidgetLoaded, win.HubSpotConversations]);

  return { chatWidgetLoaded, openChatWidget };
};
