import React, { ComponentType } from "react";
import { LoadingSpinner } from "../SvgLibrary";
import {
  BUTTON_SIZE_CLASSNAME,
  ButtonColor,
  ButtonIconVariant,
  ButtonMode,
  ButtonSize,
  ButtonVariant,
  ICON_ONLY_STYLES,
  ICON_SIZE_CLASSNAME,
  STYLE_MAP
} from "./styles";
import { useLocation } from "react-router-dom";

export const ButtonDefaultAsTypeConst = "button" as const;
export type ButtonDefaultAsType = typeof ButtonDefaultAsTypeConst;

export type ButtonOwnProps<E extends React.ElementType> = {
  children?: React.ReactNode;
  as?: E;
  preserveQueryParams?: boolean;
} & ButtonStyleProps;

export type ButtonProps<E extends React.ElementType> = ButtonOwnProps<E> &
  Omit<React.ComponentProps<E>, keyof ButtonOwnProps<E>>;

type ButtonStyleProps = {
  color?: ButtonColor;
  variant?: ButtonVariant;
  size?: ButtonSize;
  mode?: ButtonMode;
  className?: string;
  iconVariant?: ButtonIconVariant;
  Icon?: ComponentType<{
    className?: string;
  }>;
  loading?: boolean;
  iconPosition?: "left" | "right";
};

export const Button = <E extends React.ElementType = ButtonDefaultAsType>({
  className = "",
  type = "button",
  color = "primary",
  variant = "filled",
  size = "regular",
  mode = "dark",
  loading,
  Icon,
  children,
  iconVariant,
  preserveQueryParams,
  as,
  to,
  iconPosition,
  ...props
}: ButtonProps<E>) => {
  const Tag = as || ButtonDefaultAsTypeConst;
  const { search } = useLocation();

  return (
    <Tag
      className={`${className} ${STYLE_MAP[mode][variant][color]} ${
        BUTTON_SIZE_CLASSNAME[size]
      } ${
        iconVariant &&
        variant === "iconOnly" &&
        ICON_ONLY_STYLES[mode][iconVariant][color]
      } `}
      type={type}
      to={preserveQueryParams ? to + search : to}
      {...props}
    >
      {loading && (
        <LoadingSpinner mode={variant === "filled" ? "dark" : "light"} />
      )}
      {children}
      {!loading && Icon && (
        <span className={iconPosition === "left" ? "-order-1" : ""}>
          <Icon className={ICON_SIZE_CLASSNAME[size]} />
        </span>
      )}
    </Tag>
  );
};
